import { gql } from "@apollo/client";

export const REGISTER_USER = gql`
  mutation Register(
    $displayName: String!
    $username: String!
    $password: String!
  ) {
    register(
      displayName: $displayName
      username: $username
      password: $password
    ) {
      _id
      displayName
      username
    }
  }
`;

export const LOGIN_USER = gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
    }
  }
`;

export const FETCH_USER = gql`
  query FetchUser($access_token: String!) {
    fetchUser(access_token: $access_token) {
      _id
      username
      displayName
    }
  }
`;

export const GET_FANPAGES_BY_OWNER = gql`
  query GetFanpagesByOwner($userId: String) {
    getFanpagesByOwner(userId: $userId) {
      _id
      accessToken
      name
      owner
      pageId
    }
  }
`;

export const GET_CHATS_BY_FANPAGE = gql`
  query GetChatsByFanpage($fanpageId: ID!) {
    getChatsByFanpage(fanpageId: $fanpageId) {
      _id
      senderId
      name
      profile_pic
      fanpage {
        _id
        name
        pageId
      }
    }
  }
`;

export const GET_MESSAGES_BY_CHAT = gql`
  query GetMessagesByChat($chatId: ID!) {
    getMessagesByChat(chatId: $chatId) {
      _id
      message
      messageId
      psid
      fromFanpage
      timestamp
      media {
        fileName
        size
        url
        mediaType
      }
      chat {
        _id
        name
        profile_pic
        senderId
      }
    }
  }
`;

export const MESSAGE_RECEIVED_SUBSCRIPTION = gql`
  subscription OnMessageReceived($chatId: ID!) {
    messageReceived(chatId: $chatId) {
      _id
      message
      messageId
      psid
      fromFanpage
      timestamp
      media {
        fileName
        size
        url
        mediaType
        __typename
      }
      chat {
        _id
        name
        profile_pic
        senderId
        __typename
      }
      __typename
    }
  }
`;

export const NEW_FANPAGE = gql`
  subscription NewFanpage($userId: ID!) {
    newFanpage(userId: $userId) {
      _id
      accessToken
      name
      owner
      pageId
      __typename
    }
  }
`;

export const ADD_FANPAGE = gql`
  mutation Mutation($input: AddFanpageInput!) {
    addFanpage(input: $input) {
      _id
      accessToken
      name
      owner
      pageId
      __typename
    }
  }
`;

export const REMOVE_FANPAGE = gql`
  mutation RemoveFanpage($fanpageId: ID!) {
    removeFanpage(fanpageId: $fanpageId)
  }
`;

export const UPDATE_FANPAGE = gql`
  mutation UpdateFanpage($_id: ID!, $input: AddFanpageInput!) {
    updateFanpage(_id: $_id, input: $input) {
      _id
      accessToken
      name
      owner
      pageId
      __typename
    }
  }
`;

export const NEW_FANPAGE_VARIABLES = {
  userId: "",
  input: {
    name: "",
    email: "",
    owner: "",
    pageId: "",
    accessToken: "",
  },
};

export const LINK_FANPAGE = gql`
  mutation LinkFanpage($accessToken: String!) {
    linkFanpage(accessToken: $accessToken) {
      _id
      pageId
      name
    }
  }
`;

// Lấy danh sách chat
export const GET_CHAT_LIST = gql`
  query GetChatList($pageId: String!) {
    chats(pageId: $pageId) {
      _id
      userName
      lastMessage
    }
  }
`;

// Lấy danh sách tin nhắn
export const GET_MESSAGES = gql`
  query GetMessages($chatId: String!) {
    messages(chatId: $chatId) {
      _id
      content
      timestamp
      sender
    }
  }
`;

export const SEND_MESSAGE = gql`
  mutation SendMessage($chatId: ID!, $message: String, $media: MediaInput) {
    sendMessage(chatId: $chatId, message: $message, media: $media)
  }
`;

export const UPLOAD_IMAGE = gql`
  mutation UploadImage($file: Upload!) {
    uploadImage(file: $file) {
      filename
      minetype
      url
    }
  }
`;
