import React, { useEffect, useState } from "react";
import { callGraphQL } from "../utils/api";
import {
  GET_FANPAGES_BY_OWNER,
  ADD_FANPAGE,
  UPDATE_FANPAGE,
  REMOVE_FANPAGE,
  NEW_FANPAGE,
} from "../graphql/queries";
import { RiEdit2Line } from "react-icons/ri";
import { RiDeleteBin6Line } from "react-icons/ri";
import EditFanpageModal from "./EditFanpage";
import AddFanpageModal from "./AddFanpage";

const FanpageList = ({ userId, onSelectFanpage }) => {
  const [fanpages, setFanpages] = useState([]);
  const [fanpageSelected, setFanpageSelected] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const authUser = JSON.parse(sessionStorage.getItem("user"));
  const [currentUserId, setCurrentUserId] = useState(userId || authUser._id);

  const fetchFanpages = async () => {
    if (!currentUserId) return;
    const variables = userId ? { userId } : { userId: currentUserId };
    try {
      const data = await callGraphQL(GET_FANPAGES_BY_OWNER, variables);
      setFanpages(data.getFanpagesByOwner || []);
    } catch (error) {
      console.error("Error fetching fanpages:", error);
    }
  };

  // Fetch fanpages on component mount or when userId changes
  useEffect(() => {
    fetchFanpages();
  }, [currentUserId]);

  // Subscription simulation for real-time fanpage updates
  const subscribeToFanpageUpdates = async () => {
    if (!currentUserId) return;
    try {
      const data = await callGraphQL(NEW_FANPAGE, {
        userId: currentUserId,
      });
      console.log("🚀 ~ subscribeToFanpageUpdates ~ data:", data);
      const updatedFanpage = data.addFanpage;
      setFanpages((prev) =>
        prev.map((page) =>
          page._id === updatedFanpage._id ? updatedFanpage : page
        )
      );
    } catch (error) {
      console.error("Error in fanpage subscription:", error);
    }
  };

  // Fetch real-time updates
  useEffect(() => {
    subscribeToFanpageUpdates();
  }, [userId]);

  const handleOpenEditFanpage = (fanpage) => {
    setFanpageSelected(fanpage);
    setIsEditModalOpen(true);
  };

  const handleCloseEditFanpage = () => {
    setFanpageSelected(null);
    setIsEditModalOpen(false);
  };

  const handleCreateOpen = () => {
    setIsAddModalOpen(true);
  };

  const handleCreateClose = () => {
    setIsAddModalOpen(false);
  };

  const handleConfirmDeleteOpen = (fanpage) => {
    setFanpageSelected(fanpage);
    setConfirmDeleteOpen(true);
  };

  const handleConfirmDeleteClose = () => {
    setConfirmDeleteOpen(false);
    setFanpageSelected(null);
  };

  // Handle deleting a fanpage
  const handleDeleteFanpage = async () => {
    try {
      await callGraphQL(
        REMOVE_FANPAGE,
        { fanpageId: fanpageSelected._id },
        true
      );
      setFanpages((prev) =>
        prev.filter((page) => page._id !== fanpageSelected._id)
      );

      // fetchFanpages();
      handleConfirmDeleteClose();
    } catch (error) {
      console.error("Error deleting fanpage:", error);
    }
  };

  return (
    <div className="w-1/6 container mx-auto p-2 bg-gray-100 rounded-lg shadow-lg">
      <div className="mb-2 w-full">
        <button
          onClick={() => handleCreateOpen()}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
        >
          Add Fanpage
        </button>
      </div>

      {fanpages.length > 0 ? (
        <ul className="w-full fanpage-height overflow-y-auto no-scrollbar">
          {fanpages.map((page) => (
            <li
              key={page.pageId}
              onClick={() => onSelectFanpage(page._id)}
              className="flex bg-white hover:bg-green-300 p-3 shadow rounded-lg border border-gray-200 cursor-pointer"
            >
              <>
                <div className="flex-1">
                  <h3 className="font-semibold text-lg">{page.name}</h3>
                  <p className="text-gray-600">ID: {page.pageId}</p>
                </div>
                <div className="flex flex-col gap-1">
                  <button
                    onClick={() => handleOpenEditFanpage(page)}
                    className="bg-sky-500 hover:bg-sky-600 text-white font-bold py-1 px-2 rounded"
                  >
                    <RiEdit2Line />
                  </button>
                  <button
                    onClick={() => handleConfirmDeleteOpen(page)}
                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded"
                  >
                    <RiDeleteBin6Line />
                  </button>
                </div>
              </>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-center text-gray-500">No fanpages linked.</p>
      )}
      {isEditModalOpen && fanpageSelected && (
        <EditFanpageModal
          fanpage={fanpageSelected}
          setFanpages={setFanpages}
          fetchFanpages={() => fetchFanpages()}
          onClose={handleCloseEditFanpage}
        />
      )}
      {isAddModalOpen && (
        <AddFanpageModal
          userId={userId || authUser._id}
          setFanpages={setFanpages}
          fetchFanpages={() => fetchFanpages()}
          handleCreateClose={() => handleCreateClose()}
        />
      )}
      {confirmDeleteOpen && fanpageSelected && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white w-full max-w-md mx-auto rounded-lg shadow-lg p-6 z-50">
            <h2 className="text-2xl font-semibold mb-4">Xác nhận xoá</h2>
            <p>Bạn chắc chắn muốn xoá fanpage này không ?</p>
            <div className="mt-6 flex justify-end space-x-2">
              <button
                onClick={handleConfirmDeleteClose}
                className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              >
                Đóng
              </button>
              <button
                onClick={handleDeleteFanpage}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                Xoá
              </button>
            </div>
          </div>
          <div
            className="fixed inset-0 bg-black opacity-50 z-40"
            onClick={handleConfirmDeleteClose}
            style={{ pointerEvents: "auto" }}
          ></div>
        </div>
      )}
    </div>
  );
};

export default FanpageList;
