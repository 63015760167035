import React, { useState, useEffect } from "react";
import { GET_CHATS_BY_FANPAGE } from "../graphql/queries";
import { callGraphQL } from "../utils/api";
import { DEFAULT_IMG } from "../utils/constant";

const ChatList = ({ fanpageId, onSelectChat }) => {
  const [chats, setChats] = useState([]);

  useEffect(() => {
    if (fanpageId) {
      const fetchChats = async () => {
        const data = await callGraphQL(GET_CHATS_BY_FANPAGE, {
          fanpageId: fanpageId,
        });

        setChats(data.getChatsByFanpage || []);
      };
      fetchChats();
    } else {
      setChats([]);
    }
  }, [fanpageId]);

  return (
    <>
      {fanpageId && (
        <div className="w-72 max-h-full overflow-y-auto bg-gray-50 p-3 border-r">
          <h2 className="text-lg font-semibold mb-2">List Chats</h2>
          {fanpageId ? (
            <div className="chat-height overflow-y-auto">
              <ul className="chat-height list-chat overflow-y-auto no-scrollbar">
                {chats.map((chat, index) => (
                  <li
                    key={index}
                    onClick={() => onSelectChat(chat._id)}
                    className="mb-1 p-2 border border-blue-300 hover:bg-gray-200 cursor-pointer rounded-lg"
                  >
                    <div className="flex items-center content-center gap-2">
                      <img
                        className="w-10 h-10 border rounded-full"
                        src={DEFAULT_IMG}
                        alt={`Avatar ${chat.senderId}`}
                      />
                      <p className="font-semibold">{chat.name}</p>
                      {/* <p className="font-semibold">{chat._id}</p> */}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <p className="text-gray-500">Chưa chọn fanpage nào.</p>
          )}
        </div>
      )}
    </>
  );
};

export default ChatList;
