import React, { useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { REGISTER_USER, LOGIN_USER, FETCH_USER } from "../graphql/queries";

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [isRegistering, setIsRegistering] = useState(false);

  const [registerUser] = useMutation(REGISTER_USER);
  const [loginUser] = useMutation(LOGIN_USER);
  const [fetchUser, { data, loading, error }] = useLazyQuery(FETCH_USER);

  // Xử lý đăng ký
  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const { data } = await registerUser({
        variables: {
          displayName,
          username,
          password,
        },
      });
      alert(`Đăng ký thành công! Chào mừng, ${data.register.displayName}`);
      setIsRegistering(false);
    } catch (error) {
      console.log(error);
      alert("Đăng ký thất bại. Vui lòng thử lại.");
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const { data } = await loginUser({
        variables: {
          username,
          password,
        },
      });
      if (data.login.token) {
        const user = await fetchUserByToken(data.login.token);
        onLogin(data.login.token, user?._id);
      }
    } catch (error) {
      console.error(error);
      alert("Đăng nhập thất bại. Vui lòng thử lại.");
    }
  };

  const fetchUserByToken = async (access_token) => {
    try {
      const { data } = await fetchUser({
        variables: {
          access_token,
        },
      });
      if (data.fetchUser) {
        sessionStorage.setItem("user", JSON.stringify(data.fetchUser));
      }
      return data.fetchUser || {};
    } catch (error) {
      console.error(error);
      alert("Fetch user failed. Vui lòng thử lại.");
    }
  };

  return (
    <div className="flex h-screen justify-center items-center bg-gradient-to-r from-blue-500 to-indigo-600">
      <form
        className="bg-white p-10 rounded-lg shadow-lg w-96"
        onSubmit={isRegistering ? handleRegister : handleLogin}
      >
        <h2 className="text-3xl font-bold text-center mb-8 text-gray-800">
          {isRegistering ? "Tạo Tài Khoản" : "Đăng Nhập"}
        </h2>

        {isRegistering && (
          <div className="mb-6">
            <label className="block text-gray-600 mb-2">Tên hiển thị</label>
            <input
              type="text"
              placeholder="Display Name"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        )}

        <div className="mb-6">
          <label className="block text-gray-600 mb-2">Tên đăng nhập</label>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div className="mb-6">
          <label className="block text-gray-600 mb-2">Mật khẩu</label>
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition-colors mb-4"
        >
          {isRegistering ? "Đăng Ký" : "Đăng Nhập"}
        </button>

        <button
          type="button"
          className="w-full text-blue-500 hover:underline"
          onClick={() => setIsRegistering(!isRegistering)}
        >
          {isRegistering
            ? "Đã có tài khoản? Đăng nhập"
            : "Chưa có tài khoản? Đăng ký"}
        </button>
      </form>
    </div>
  );
};

export default Login;
