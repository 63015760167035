import React, { useState, useEffect } from "react";
import {
  GET_MESSAGES_BY_CHAT,
  MESSAGE_RECEIVED_SUBSCRIPTION,
  SEND_MESSAGE,
  UPLOAD_IMAGE,
} from "../graphql/queries";
import { callGraphQL } from "../utils/api";
import { FormatTimeMessage } from "../utils/helper";
import { useSubscription } from "@apollo/client";
import { GrSend } from "react-icons/gr";
import { RiAttachment2 } from "react-icons/ri";
import { LiaTimesSolid } from "react-icons/lia";

const MessageBox = ({ selectedChatId }) => {
  const [messages, setMessages] = useState([]);
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const [filePreview, setFilePreview] = useState(null);

  const { data: subscriptionData } = useSubscription(
    MESSAGE_RECEIVED_SUBSCRIPTION,
    {
      skip: !selectedChatId,
      variables: { chatId: selectedChatId },
    }
  );

  useEffect(() => {
    if (selectedChatId) {
      fetchMessages(selectedChatId);
    }
  }, [selectedChatId]);

  useEffect(() => {
    if (subscriptionData && subscriptionData.messageReceived) {
      const newMessage = subscriptionData.messageReceived;
      setMessages((prevMessages) => {
        const isDuplicate = prevMessages.some(
          (msg) => msg.messageId === newMessage.messageId
        );
        if (!isDuplicate) {
          return [newMessage, ...prevMessages];
        }
        return prevMessages;
      });
    }
  }, [subscriptionData]);

  const fetchMessages = async (chatId) => {
    const data = await callGraphQL(GET_MESSAGES_BY_CHAT, {
      chatId: chatId,
    });
    setMessages(data.getMessagesByChat || []);
  };

  const handleSendMessage = async () => {
    try {
      if (!message && !file) return;

      const variables = {
        chatId: selectedChatId,
        message: message.trim(),
        media: null,
      };

      if (file) {
        const fileUploaded = await handleUploadImage();
        console.log("🚀 ~ handleSendMessage ~ fileUploaded:", fileUploaded);
        variables.media = {
          filename: fileUploaded.filename,
          url: fileUploaded.url,
          minetype: fileUploaded.minetype,
        };
      }
      await callGraphQL(SEND_MESSAGE, variables, true);
      setMessage("");
      setFile(null);
      setFilePreview(null);
    } catch (error) {
      console.log("handleSendMessage err: ", error);
    }
  };

  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile);

    // Tạo URL xem trước nếu là hình ảnh
    if (uploadedFile && uploadedFile.type.startsWith("image/")) {
      setFilePreview(URL.createObjectURL(uploadedFile));
    } else {
      setFilePreview(null);
    }
  };

  const handleUploadImage = async () => {
    const data = await callGraphQL(
      UPLOAD_IMAGE,
      {
        file: file,
      },
      true
    );
    return data.uploadImage;
  };

  const handleClearUpload = () => {
    setFilePreview(null);
    setFile(null);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && message.trim()) {
      handleSendMessage();
    }
  };

  return (
    <>
      {selectedChatId && (
        <div className="flex-1 h-screen max-h-full overflow-y-auto p-3">
          <h2 className="text-lg font-semibold mb-2">Lists Messages</h2>
          <div className="border border-blue-400 p-3 max-h-full overflow-y-auto no-scrollbar rounded-lg">
            <div className="flex flex-1 flex-col-reverse h-screen max-height overflow-y-auto no-scrollbar">
              {messages.length > 0 &&
                messages.map((msg, index) => (
                  <div
                    key={index}
                    className={`${
                      msg.media ? "block" : "inline-block"
                    } p-2 border rounded-lg mt-2 ${
                      msg.fromFanpage
                        ? "bg-blue-100 self-end"
                        : "bg-gray-50 self-start"
                    }`}
                  >
                    {msg.media && (
                      <div
                        className={`grid rounded-lg ${
                          msg.media.length > 1 ? "grid-cols-2" : "grid-cols-1"
                        } gap-1 max-w-96`}
                      >
                        {msg.media.map((item, index) => (
                          <img
                            key={index}
                            className="border rounded-lg h-auto transform transition duration-300 scale-101"
                            src={item.url}
                            alt={`Image ${index}`}
                          />
                        ))}
                      </div>
                    )}
                    {/* Nội dung text của tin nhắn */}
                    {msg.message && (
                      <p className="text-gray-800">{msg.message}</p>
                    )}
                    <p className="text-right text-sm text-gray-500 mt-1">
                      {<FormatTimeMessage timestamp={msg.timestamp} />}
                    </p>
                  </div>
                ))}
            </div>
          </div>
          <div className="mt-2 flex items-center border border-gray-300 rounded px-2 py-1 relative">
            {filePreview && (
              <div className="absolute bottom-12 left-2 mb-2 p-2">
                <div className="relative">
                  <img
                    src={filePreview}
                    alt="Preview"
                    className="w-40 object-fill rounded"
                  />
                  <LiaTimesSolid
                    className="absolute top-0 right-0 text-gray-500 hover:text-gray-950 cursor-pointer"
                    onClick={() => handleClearUpload()}
                  />
                </div>
              </div>
            )}
            {/* Icon attachment bên trong input */}
            <label htmlFor="file-upload" className="cursor-pointer">
              <RiAttachment2 className="text-gray-500 mr-2 text-2xl hover:text-gray-950" />
            </label>
            <input
              id="file-upload"
              type="file"
              onChange={handleFileChange}
              className="hidden"
            />

            {/* Input cho tin nhắn với icon send bên trong */}
            <input
              type="text"
              placeholder="Type a message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={handleKeyDown}
              className="w-full p-1 focus:outline-none"
            />

            {/* Icon button send bên trong input */}
            <button
              onClick={handleSendMessage}
              className="text-blue-500 ml-2 flex flex-1 items-center gap-1 cursor-pointer bg-gray-300 hover:bg-blue-500 hover:text-white p-1 rounded font-semibold"
              disabled={!message.trim() && !file}
            >
              Send <GrSend className="text-xl" />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default MessageBox;
