import { useEffect, useState } from "react";
import { UPDATE_FANPAGE } from "../graphql/queries";
import { callGraphQL } from "../utils/api";

const EditFanpageModal = ({ fanpage, setFanpages, fetchFanpages, onClose }) => {
  const [editFormFanpage, setEditFormFanpage] = useState({
    name: fanpage.name,
    pageId: fanpage.pageId,
    accessToken: fanpage.accessToken || "",
  });

  useEffect(() => {
    if (fanpage) {
      setEditFormFanpage({
        name: fanpage.name,
        pageId: fanpage.pageId,
        accessToken: fanpage.accessToken || "",
      });
    }
  }, []);

  // Handle editing a fanpage
  const handleEditFanpage = async () => {
    console.log("editFormFanpage: ", editFormFanpage);
    try {
      const data = await callGraphQL(
        UPDATE_FANPAGE,
        {
          _id: fanpage._id,
          input: editFormFanpage,
        },
        true
      );
      setFanpages((prev) =>
        prev.map((page) =>
          page._id === data.updateFanpage._id ? data.updateFanpage : page
        )
      );
      // fetchFanpages();
      setEditFormFanpage(null);
      onClose();
    } catch (error) {
      console.error("Error editing fanpage:", error);
    }
  };

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
          <h3 className="font-bold text-lg mb-4">Edit Fanpage</h3>
          <input
            type="text"
            placeholder="Name"
            value={editFormFanpage.name}
            onChange={(e) =>
              setEditFormFanpage({ ...editFormFanpage, name: e.target.value })
            }
            className="mb-2 p-2 border rounded w-full"
          />
          <input
            type="text"
            placeholder="Page ID"
            value={editFormFanpage.pageId}
            onChange={(e) =>
              setEditFormFanpage({
                ...editFormFanpage,
                pageId: e.target.value,
              })
            }
            className="mb-2 p-2 border rounded w-full"
          />
          <input
            type="text"
            placeholder="Access Token"
            value={editFormFanpage.accessToken}
            onChange={(e) =>
              setEditFormFanpage({
                ...editFormFanpage,
                accessToken: e.target.value,
              })
            }
            className="mb-4 p-2 border rounded w-full"
          />
          <button
            onClick={() => onClose()}
            className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded mr-2"
          >
            Cancel
          </button>
          <button
            onClick={handleEditFanpage}
            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
          >
            Edit Fanpage
          </button>
        </div>
      </div>
    </>
  );
};

export default EditFanpageModal;
