import React from "react";
import { FiLogOut } from "react-icons/fi";

const Header = ({ logout }) => {
  return (
    <header className="flex justify-between bg-blue-600 text-white p-4">
      <h1 className="text-xl font-semibold">SHPAGE</h1>
      <div className="cursor-pointer" onClick={logout}>
        <FiLogOut />
      </div>
    </header>
  );
};

export default Header;
