import React, { useState } from "react";
import Header from "./components/Header";
import ChatList from "./components/ChatList";
import MessageBox from "./components/MessageBox";
import Login from "./components/Login";
import FanpageList from "./components/FanpageList";
import { GET_MESSAGES_BY_CHAT } from "./graphql/queries";
import { callGraphQL } from "./utils/api";

const App = () => {
  const [selectedFanpageId, setSelectedFanpageId] = useState(null);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [userLoggedInId, setUserLoggedInId] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(
    Boolean(sessionStorage.getItem("token"))
  );

  const handleLogin = (token, userId) => {
    sessionStorage.setItem("token", token);
    setIsLoggedIn(true);
    setUserLoggedInId(userId);
  };

  const handleLogout = () => {
    sessionStorage.removeItem("token");
    setIsLoggedIn(false);
  };

  const handleSelectFanpage = (fanpageId) => {
    setSelectedFanpageId(fanpageId);
    setSelectedChatId(null);
  };

  const handleSelectChat = (chatId) => {
    setSelectedChatId(chatId);
  };

  return (
    <div className="flex max-h-screen h-screen flex-col flex-1 overflow-hidden">
      {!isLoggedIn ? (
        <Login onLogin={handleLogin} />
      ) : (
        <>
          <Header logout={handleLogout} />
          <div className="flex max-h-screen">
            <FanpageList
              userId={userLoggedInId}
              fanpageId={selectedFanpageId}
              onSelectFanpage={handleSelectFanpage}
            />
            <div className="flex flex-col flex-1 h-screen max-h-full ">
              <div className="flex flex-1 max-h-full">
                <ChatList
                  fanpageId={selectedFanpageId}
                  onSelectChat={handleSelectChat}
                />
                <MessageBox selectedChatId={selectedChatId} />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default App;
