import client from "../graphql/client";

export const callGraphQL = async (
  operation,
  variables = {},
  isMutation = false
) => {
  try {
    const response = isMutation
      ? await client.mutate({ mutation: operation, variables })
      : await client.query({ query: operation, variables });
    return response.data;
  } catch (error) {
    console.error("GraphQL Error:", error);
    throw error;
  }
};
