export const FormatTimeMessage = ({ timestamp }) => {
  const date = new Date(Number(timestamp));

  const formattedDate = date.toLocaleString("vi-VN", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
  return <span>{formattedDate}</span>;
};
