import { useEffect, useState } from "react";
import { ADD_FANPAGE } from "../graphql/queries";
import { callGraphQL } from "../utils/api";

const AddFanpageModal = ({
  userId,
  setFanpages,
  fetchFanpages,
  handleCreateClose,
}) => {
  const [formFanpage, setFormFanpage] = useState({
    name: "",
    pageId: "",
    accessToken: "",
  });

  // Handle adding a fanpage manually
  const handleAddFanpage = async () => {
    try {
      const data = await callGraphQL(
        ADD_FANPAGE,
        {
          input: { ...formFanpage, owner: userId },
        },
        true
      );
      setFanpages((prev) => [...prev, data.addFanpage]);
      setFormFanpage({ name: "", pageId: "", accessToken: "" });
      handleCreateClose();
      // fetchFanpages();
    } catch (error) {
      console.error("Error adding fanpage:", error);
    }
  };

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
          <h3 className="font-bold text-lg mb-4">Add Fanpage</h3>
          <input
            type="text"
            placeholder="Name"
            value={formFanpage.name}
            onChange={(e) =>
              setFormFanpage({ ...formFanpage, name: e.target.value })
            }
            className="mb-2 p-2 border rounded w-full"
          />
          <input
            type="text"
            placeholder="Page ID"
            value={formFanpage.pageId}
            onChange={(e) =>
              setFormFanpage({
                ...formFanpage,
                pageId: e.target.value,
              })
            }
            className="mb-2 p-2 border rounded w-full"
          />
          <input
            type="text"
            placeholder="Access Token"
            value={formFanpage.accessToken}
            onChange={(e) =>
              setFormFanpage({
                ...formFanpage,
                accessToken: e.target.value,
              })
            }
            className="mb-4 p-2 border rounded w-full"
          />
          <button
            onClick={handleAddFanpage}
            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded mr-2"
          >
            Add Fanpage
          </button>
          <button
            onClick={() => handleCreateClose()}
            className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

export default AddFanpageModal;
